"use client";
import Image from "next/image";
import opinion1 from "@/public/images/Opinion/1.jpg";
import opinion2 from "@/public/images/Opinion/2.jpg";
import opinion3 from "@/public/images/Opinion/3.jpg";
import opinion4 from "@/public/images/Opinion/4.jpg";
import opinion5 from "@/public/images/Opinion/5.jpg";
import opinion6 from "@/public/images/Opinion/6.jpg";
import opinion7 from "@/public/images/Opinion/7.jpg";
import opinion8 from "@/public/images/Opinion/8.jpg";
import opinion9 from "@/public/images/Opinion/9.jpg";
import opinion10 from "@/public/images/Opinion/10.jpg";
import opinion11 from "@/public/images/Opinion/11.jpg";
import opinion12 from "@/public/images/Opinion/12.jpg";
import opinion13 from "@/public/images/Opinion/13.jpg";
import opinion14 from "@/public/images/Opinion/14.jpg";
import star from "@/public/images/star.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useTranslate } from "@/components/base/providers/TranslateProv";

const texts = {
  ar: {
    title: "آراء الطلاب و آولياء آمور",
    description:
      "تعرفوا على تجارب الطلاب وأولياء الأمور الذين ساعدناهم في العثور على الكلية المناسبة لهم، واختيار أفضل الجامعات التي تتوافق مع طموحاتهم الأكاديمية وأهدافهم المستقبلية.",
    name: "ولاء غالي",
    role: "لاند روفر ديفيندر",
    testimonial:
      "ربنا يجازيك عنا و عن ولادنا كل خير و يبارك فيك و فى امثالك ...حقيقي كنت ضايعه انا و بنتى السنه اللى فاتت و مش فاهمه اى حااجه و لا عارفه اى جامعات و حاله احباط و حزن كانت صايبه البيت كله و لولا ربنا سبحانه و تعالى ثم صفحتك و ارشاداتك و المعلومات اللى كنت بتقولها مكانتش بنتى قدرت تحقق حلم حياتها و تدخل الكليه اللى طول عمرها بتحلم بيها ....كل الشكر و الاحترام و التقدير ميوفوش حقك بجد",
  },
  en: {
    title: "Students and Parents Opinions",
    description:
      "Discover the experiences of students and parents we have helped in finding the right college for them and selecting the best universities that align with their academic aspirations and future goals.",
    name: "Walaa Ghaly",
    role: "Land Rover Defender",
    testimonial:
      "God bless you and reward you for us and for our children... Honestly, I was lost last year with my daughter, not understanding anything, not knowing any universities, and the whole house was in a state of despair and sadness. If it weren't for God and then your page, your guidance, and the information you provided, my daughter wouldn't have been able to achieve her dream and enter the college she had always dreamed of... All thanks, respect, and appreciation don't do justice to your efforts.",
  },
};

const Opinion = [
  {
    id: 1,
    name: "Walaa Ghaly",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: "ربنا يجازيك عنا و عن ولادنا كل خير و يبارك فيك و فى امثالك ...حقيقي كنت ضايعه انا و بنتى السنه اللى فاتت و مش فاهمه اى حااجه و لا عارفه اى جامعات و حاله احباط و حزن كانت صايبه البيت كله و لولا ربنا سبحانه و تعالى ثم صفحتك و ارشاداتك و المعلومات اللى كنت بتقولها مكانتش بنتى قدرت تحقق حلم حياتها و تدخل الكليه اللى طول عمرها بتحلم بيها ....كل الشكر و الاحترام و التقدير ميوفوش حقك بجد",
      en: "May God reward you on our behalf and on behalf of our children with all the best and bless you and those like you... Honestly, I was lost along with my daughter last year, not understanding anything, not knowing any universities, and the whole house was in a state of depression and sadness. If it wasn't for God Almighty and then your page, guidance, and the information you provided, my daughter wouldn't have been able to achieve her lifelong dream and get into the college she always dreamed of. All thanks, respect, and appreciation are not enough to truly express our gratitude.",
    },
    image: opinion1,
  },
  {
    id: 2,
    name: "Mona Adel",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: "أشكرك جزيل الشكرأنت شخص فريد ومحترم للغايةشكرا جزيلا لك 💕 على معلوماتك القيمة",
      en: "Thank you very much, you are a unique and highly respectable person. Thank you so much 💕 for your valuable information.",
    },
    image: opinion2,
  },
  {
    id: 3,
    name: "د.عبد الحميد أبوالعلا",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: "جزاك الله خيرا وجعل ماتقدمه من معلومات قيمة وجهد مشكور في ميزان حسناتك معالي الدكتور المحترم",
      en: "May God reward you and place the valuable information and efforts you provide in the balance of your good deeds, esteemed doctor.",
    },
    image: opinion3,
  },
  {
    id: 4,
    name: "Amira Sarhan",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: "حقيقي حضرتك بتساعدنا وبتوفر علينا كتيير وبتقدم المعلومات الوافيه عن كل كليه وتساعد الطلبه وأولياء الأمور علي الأختيار السليم جزيل الشكر لحضرتك وأنا شخصيا استفدت جدا من متابعتك استمر وبالتوفيق إن شاء الله",
      en: "You truly help us a lot and provide comprehensive information about each college, assisting students and parents in making the right choices. Many thanks to you, and I personally benefited greatly from following you. Keep going, and best of luck, God willing.",
    },
    image: opinion4,
  },
  {
    id: 5,
    name: "Wagih Mikhaiel",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: "ربنا يوفقك ويساعدك؛ يبدو انك إنسان مجتهد ودي فكره رائعة ولك خالص التقدير والاحترام.",
      en: "May God grant you success and help you; you seem to be a diligent person, and this is a great idea. You have my utmost respect and appreciation.",
    },
    image: opinion5,
  },
  {
    id: 6,
    name: "Hussein Shazly",
    type: { ar: "طالب", en: "Student" },
    message: {
      ar: "ربنا يبارك في حضرتك يا دكتور بالتوفيق إن شاء الله❤️",
      en: "May God bless you, doctor. Best of luck, God willing. ❤️",
    },
    image: opinion6,
  },
  {
    id: 7,
    name: "رحاب كريم",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: " طبعا استفدنا منك واسمك خير دليل نور وانت فعلا شعاع المعلومة والخبر ومثال مشرف لشبابنا جزاك الله كل خير",
      en: "May God reward you with all the best. Thank God, I greatly benefited from your guidance when applying for my daughter two years ago.",
    },
    image: opinion4,
  },
  {
    id: 8,
    name: "Bahaa Ramadan",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: "بالتوفيق والنجاح الدائم دكتور نور مزيد من التألق والرقي جعله الله في ميزان حسناتك يارب العالمين 🙏",
      en: "Wishing you continuous success and progress, Dr. Nour. May God place it in the balance of your good deeds, O Lord of the worlds 🙏.",
    },
    image: opinion7,
  },
  {
    id: 9,
    name: "Bahaa Ramadan",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: "ربنايبارك فيك يادكتور الصفحه دى هى السبب فى دخول بنتى الكليه المناسبه ليها",
      en: "May God bless you, doctor. This page is the reason my daughter got into the right college for her.",
    },
    image: opinion8,
  },
  {
    id: 10,
    name: "Sherif Awad",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: "جزاك الله كل الخير والحمد لله انا استفدت من ارشاداتك جدا جدا خلال تقديمى لبنتى من سنتين",
      en: "May God reward you with all the best. Thank God, I greatly benefited from your guidance when applying for my daughter two years ago.",
    },
    image: opinion9,
  },
  {
    id: 11,
    name: "احمد اسماعيل مخيمر",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: "انت انسان محترم جدا ربنا يبارك فيك ويحفظك تحياتي وتقديري لحضرتك يا دكتور",
      en: "You are a very respectable person. May God bless and protect you. My greetings and appreciation to you, doctor.",
    },
    image: opinion10,
  },
  {
    id: 12,
    name: "Mona Mony",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: "جزاكم الله خيرا يكفيك إن لما نتكلم عن معلومه من صفحتك بنقول من مصدر موثوق والأمانه فى النصيحة ونقل المعلومه أكيد جزاءها عند الله عظيم",
      en: "May God reward you with all the best. It is enough to know that when we talk about information from your page, we say it's from a reliable source. Honesty in advice and conveying information surely has a great reward with God.",
    },
    image: opinion11,
  },
  {
    id: 13,
    name: "Mahenour Magdy Nofal",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: "جعله الله في ميزان حسناتك وبجد وفرت جهد كبير على اولياء الامور قبل الطلبه لان ولي الامر مهما كانت مدركاته الحياتيه عمرها ما هاتكون زي الخبرات اللي ضفتها ليهم علشان يساعدو ابنائهم جزاك الله خير جزاء",
      en: "May God place it in the balance of your good deeds. Honestly, you saved a lot of effort for parents before students because no matter how much life experience a parent has, it will never be like the expertise you provided to help them assist their children. May God reward you with the best.",
    },
    image: opinion12,
  },
  {
    id: 14,
    name: "Khaled Lotfy",
    type: { ar: "طالب ", en: "  Student" },
    message: {
      ar: "من الناس اللي بحترمهم وبثق فيهم ❤️❤️",
      en: "One of the people I respect and trust ❤️❤️.",
    },
    image: opinion13,
  },
  {
    id: 15,
    name: "Awatif Ahmed",
    type: { ar: "ولي امر", en: "Parent of a Student" },
    message: {
      ar: "حضرتك اكتر مصري محترم شوفته بحياتي ربنا يعزك و يكرمك",
      en: "You are the most respectable Egyptian I have ever met in my life. May God honor and bless you.",
    },
    image: opinion14,
  },
];

// console.log(data);

const Index = () => {
  const { locale }: { locale: "ar" | "en" } = useTranslate();

  const t = texts[locale];

  return (
    <div className="bg-white">
      <div className="container py-[100px]">
        <p className="text-25 text-darkText font-[600] text-center mb-5">
          {t.title}
        </p>
        <p className="text-20 text-[#6C7278] text-center max-w-[723px] mx-auto mb-10">
          {t.description}
        </p>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={10}
          slidesPerView={"auto"}
          breakpoints={{
            370: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
          autoplay={{ delay: 2000 }}
          pagination={{
            clickable: true,
            renderBullet(index, className) {
              return `<span class="${className} !rounded-[10px] !top-2 !bg-primaryColor !w-[45px] !h-[6px] transition"></span>`;
            },
          }}
          dir={locale === "ar" ? "rtl" : "ltr"}
          className="!pb-16"
        >
          {Opinion?.map((item, index) => (
            <SwiperSlide key={item?.id}>
              <div className="bg-white border border-[#EBEBEB] rounded-[10px] py-10 px-5  min-h-[315px] max-h-[315px]">
                <div className="flex items-center gap-3 w-fit mx-auto mb-10">
                  <Image
                    className="rounded-full w-[60px] h-[60px]"
                    src={item?.image}
                    width={40}
                    height={40}
                    alt="opinion"
                  />
                  <div>
                    <p className="text-20 text-textDark font-[500]">
                      {item?.name}
                    </p>
                    <p className="text-18 text-[#6C7278]">
                      {item?.type[locale]}
                    </p>
                  </div>
                </div>
                <div className="border border-[#EBEBEB] rounded-[10px] p-5 relative pt-10">
                  <div className="absolute bg-white w-fit flex items-center gap-3 py-2 px-5 rounded-full border border-[#EBEBEB] -top-4 left-1/2 -translate-x-1/2">
                    {[...Array(5)].map((_, i) => (
                      <Image key={i} src={star} alt="star" />
                    ))}
                  </div>
                  <p className="text-18 text-[#6C7278] line-clamp-3">
                    {item?.message[locale]}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Index;
